import { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import { useTheme } from "../../../../context/ThemeContext";
import { Badge, Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useNotificationsService } from "../../../../context/firebase/NotificationsContext";
import { useAuthService } from "../../../../context/firebase/AuthContext";
import usePremiumUser from "../../../../global/hooks/usePremiumUser";
import { baseUrl } from "../../../../global/utils";
import CustomDialog from "../../../../components/CustomDialog";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";

const HomeHeader = () => {
  const [showHeader, setShowHeader] = useState(true);
  const { notifications } = useNotificationsService();
  const { user } = useAuthService();
  const { isPremiumUser } = usePremiumUser();
  const { mode } = useTheme();
  const navigate = useNavigate();
  let lastScrollY = window.scrollY;

  const [data, setData] = useState<any>();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  const handleScroll = () => {
    if (window.scrollY > lastScrollY) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
    lastScrollY = window.scrollY;
  };

  const handleClickNotifications = () => {
    navigate("notifications");
  };

  const handleClickChat = () => {
    if (isPremiumUser(user)) {
      navigate("chat");
    } else {
      navigate("/root/premium");
    }
  };

  const handleClickLeaderboard = () => {
    navigate("leaderboard");
  };

  const unreadCount = notifications?.filter(
    (notification) => !notification.isRead
  ).length;

  return (
    <AppBar
      position="fixed"
      sx={{ bgcolor: mode === "dark" ? "#333" : "#fff" }}
      style={{
        top: showHeader ? 0 : "-64px",
        transition: "top 0.3s",
      }}
    >
      <Toolbar>
        <Stack
          direction={"row"}
          alignItems={"center"}
          flex={1}
          justifyContent={"space-between"}
        >
          <Typography variant="h5">For you</Typography>

          <Stack direction={"row"} spacing={1}>
            <IconButton size="small" onClick={handleClickLeaderboard}>
              <Badge badgeContent={"New"} color="success">
                <LeaderboardIcon />
              </Badge>
            </IconButton>
            <IconButton size="small" onClick={handleClickChat}>
              <Badge badgeContent={"Pro"} color="warning">
                <ChatOutlinedIcon />
              </Badge>
            </IconButton>
            <IconButton size="small" onClick={handleClickNotifications}>
              {unreadCount && unreadCount > 0 ? (
                <Badge
                  badgeContent={unreadCount > 9 ? "9+" : unreadCount}
                  color="error"
                >
                  <NotificationsOutlinedIcon />
                </Badge>
              ) : (
                <NotificationsOutlinedIcon />
              )}
            </IconButton>
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default HomeHeader;
