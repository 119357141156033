import { Avatar, Box, Stack, Typography } from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import { useUserService } from "../../../context/firebase/UserContext";
import StarIcon from "@mui/icons-material/Star";
import usePremiumUser from "../../../global/hooks/usePremiumUser";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { User } from "../../../firebase/types";

const Leaderboard = () => {
  const navigate = useNavigate();
  const { isPremiumUser } = usePremiumUser();
  const { getAndSetCurrentStreakUser, getAndSetLongestStreakUser } =
    useUserService();

  const [longestStreaksUser, setLongestStreaksUser] = useState<User[]>([]);
  const [currentStreaksUser, setCurrentStreaksUser] = useState<User[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const longestStreaks = await getAndSetLongestStreakUser();
      setLongestStreaksUser(longestStreaks);

      const currentStreaks = await getAndSetCurrentStreakUser();
      setCurrentStreaksUser(currentStreaks);
    };

    fetchData();
  }, [getAndSetCurrentStreakUser, getAndSetLongestStreakUser]);

  return (
    <>
      <Stack
        direction={"row"}
        spacing={2}
        alignSelf={"center"}
        alignItems={"baseline"}
      >
        <ElectricBoltIcon color="warning" />
        <Typography textAlign={"center"} variant="h4">
          Leaderboard
        </Typography>
        <EmojiEventsIcon color="primary" />
      </Stack>
      <Typography textAlign={"center"} variant="body2" pb={2}>
        See who's on top 100 of the leaderboard!
      </Typography>

      <Box display="flex" height="100vh" maxWidth="100vw" overflow="hidden">
        {/* Best Current Streaks Section */}
        <Stack
          flex={1}
          sx={{ overflowY: "auto", overflowX: "hidden", minWidth: 0 }}
          p={2}
          spacing={2}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography variant="h5">Best Current Streaks</Typography>
            <ElectricBoltIcon color="warning" />
          </Stack>

          <Stack spacing={1}>
            {currentStreaksUser?.map((user, index) => (
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={1}
                key={index}
                onClick={() => navigate(`/root/user/${user.uid}`)}
                width="100%"
              >
                <Avatar
                  sx={{
                    width: 50,
                    height: 50,
                  }}
                  src={user?.photoURL}
                />
                <Stack alignItems={"flex-start"} width="100%">
                  <Stack direction="row" alignItems="center">
                    <Typography fontWeight={"bold"}>
                      {user?.username}
                    </Typography>
                    {isPremiumUser(user) && (
                      <StarIcon color={"warning"} fontSize={"small"} />
                    )}
                  </Stack>
                  <Typography variant={"caption"}>
                    {user.currentStreak ?? 0} - Current Streak
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>

        {/* Best Longest Streaks Section */}
        <Stack
          flex={1}
          sx={{ overflowY: "auto", overflowX: "hidden", minWidth: 0 }}
          p={2}
          spacing={2}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography variant="h5">Best Longest Streaks</Typography>
            <EmojiEventsIcon color="primary" />
          </Stack>
          <Stack spacing={1}>
            {longestStreaksUser?.map((user, index) => (
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={1}
                key={index}
                onClick={() => navigate(`/root/user/${user.uid}`)}
                width="100%"
              >
                <Avatar
                  sx={{
                    width: 50,
                    height: 50,
                  }}
                  src={user?.photoURL}
                />
                <Stack alignItems={"flex-start"} width="100%">
                  <Stack direction="row" alignItems="center">
                    <Typography fontWeight={"bold"}>
                      {user?.username}
                    </Typography>
                    {isPremiumUser(user) && (
                      <StarIcon color={"warning"} fontSize={"small"} />
                    )}
                  </Stack>
                  <Typography variant={"caption"}>
                    {user.longestStreak ?? 0} - Longest Streak
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default Leaderboard;
